import axios from 'axios';
import { Message, MessageBox } from 'element-ui';

const service = axios.create({
  withCredentials: false,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  }
})

service.interceptors.request.use(
  config => {
    return config
  },
  
  error => {
    // Do something with request error 
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;

    return res;
  },
  error => {
    var errorStatus = error.response.code;
    var errorData = error.response.data;
    var messageTxt = "";
    if (errorStatus != 200) {
      messageTxt = "服务器内部错误，请联系管理员";
    } else {
      messageTxt = '失败原因：' + errorData.code + '--' + errorData.repMsg;
    }
    Message({
      message: messageTxt,
      type: 'error',
      duration: 5 * 1000
    })
  }
)

export default service
